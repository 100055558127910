/* eslint-disable react/prop-types */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Anchor, Flex, ScrollArea, Select, Text, Center } from '@mantine/core';
import dayjs from 'dayjs';
import { IconActivity, IconAdjustments, IconEdit, IconInfoCircle, IconMail } from '@tabler/icons-react';
import { useOrganizationsFilter } from '../../account/AccountFilters';
import { Header, NumberCell } from '../../core/ReactTable/ReactTable';
import { useSelector } from 'react-redux';
import { selectAllParamsQueryData } from '../../core/ReactTable/paramsSlice';
import { useGetBatteriesQuery } from '../../../redux/query/hire/cyclesApi.slice';
import { useUpdateNotificationFrequencyForCycleMutation } from '../../../redux/query/hire/emailNotificationsApi.slice';
import { notifications } from '@mantine/notifications';

const defaultFilters = { active: '0' }
const defaultHiddenColumns = ['id']

export function useBatteriesQuery (namespace, skip = false) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = useGetBatteriesQuery(queryParams, { skip })
  return [collection ?? null, querying]
}

export function useBatteryTable () {
  const columns = useBatteryColumns()
  const filters = useBatteryFilters()
  const actions = useBatteryActions()

  return {
    defaultFilters: defaultFilters,
    defaultHiddenColumns: defaultHiddenColumns,
    columns: columns,
    filters: filters,
    actions: actions,
    onRowClick: onRowClick,
    searchable: true
  }
}

function onRowClick (row) {
  if (row === undefined) return true
  const batteryId = row.dataset.rowid
  console.debug('Battery row clicked - redirecting to battery results', batteryId)
  window.location.href = `/cycles/${batteryId}/legacy` // Note that for /results, ap cycles always redirect to /legacy
}

function useBatteryColumns () {
  const ROW_HEIGHT = '6rem'
  return useMemo(() => [
    {
      Header: <Header>ID</Header>,
      id: 'id',
      accessor: 'id',
      Cell: ({ cell: { value } }) => {
        return <NumberCell>{value}</NumberCell>
      },
      sortable: true,
      hideable: true
    }, {
      Header: <Header>Name</Header>,
      id: 'name',
      accessor: 'name',
      Cell: ({ cell: { value } }) => {
        return <Text>{value}</Text>
      },
      sortable: true
    }, {
      Header: <Header>Assessments</Header>,
      id: 'assessments',
      accessor: 'ap_cycle_surveys',
      Cell: ({ cell: { value, row } }) => {
        return (
          <ScrollArea h={ROW_HEIGHT}>
            <Flex direction='column'>
            {value.map((assessment) => {
              const unpublishedAssessment = assessment.published_survey.unpublished_assessment
              if (!unpublishedAssessment) {
                if (assessment.published_survey) {
                  return <Text key={`${row.id}-${assessment.id}`}>{assessment.published_survey.name}</Text>
                }
                return null
              }
              return <Anchor
                key={`${row.id}-${assessment.id}`}
                href={`/build/assessments/${unpublishedAssessment.id}/edit`}
                >
                {unpublishedAssessment.name}
              </Anchor>
            })}
            </Flex>
          </ScrollArea>
        )
      },
      hideable: true
    }, {
      Header: <Header ta='right'># Completed</Header>,
      id: 'completed',
      accessor: 'number_completed',
      Cell: ({ cell: { value } }) => {
        return <NumberCell>{value}</NumberCell>
      },
      hideable: true,
      sortable: true
    }, {
      Header: <Header ta='right'># Cycles</Header>,
      id: 'cycles',
      accessor: 'number_cycles',
      Cell: ({ cell: { value } }) => {
        return <NumberCell>{value}</NumberCell>
      },
      hideable: true,
      sortable: true
    }, {
      Header: <Header ta='right'># Organizations</Header>,
      id: 'organizations',
      accessor: 'number_organizations',
      Cell: ({ cell: { value } }) => {
        return <NumberCell>{value}</NumberCell>
      },
      hideable: true,
      sortable: true
    }, {
      Header: <Header ta='center'>Created Date</Header>,
      id: 'created_at',
      accessor: 'created_at',
      Cell: ({ cell: { value } }) => {
        return <NumberCell centered>{dayjs(value).format('MM-DD-YYYY')}</NumberCell>
      },
      hideable: true,
      sortable: true
    }, {
      Header: <Header ta='center'><Center><IconMail size='1.25rem' />Notifications</Center></Header>,
      id: 'notifications',
      accessor: (row) => row,
      Cell: NotificationCell,
      hideable: true,
      sortable: false
    }
  ], [])
}

const CycleNotificationFrequency = Object.freeze({ // Note: no "Instant" (100) value for batteries.
  Never: 0,
  Daily: 1,
  Weekly: 7,
  Monthly: 30
})

const cycleNotificationFrequencyIdToName = Object.freeze(
  Object.fromEntries(
    Object.entries(CycleNotificationFrequency)
      .map(([key, value]) => [value, key])
  )
)

const cycleNotificationFrequencyOptions = Object.freeze(
  Object.entries(CycleNotificationFrequency)
    .map(([key, value]) => ({ value: value.toString(), label: key }))
)

/**
 * @param row
 * @returns {string} int value converted to string if value matches expected possible options, otherwise '0'.
 */
function parseNotificationFrequencyFromSource (row) {
  const sourceValue = row.email_notifications?.[0]?.frequency ?? 0
  return (cycleNotificationFrequencyIdToName[sourceValue] ?? null) ? sourceValue.toString() : '0'
}

function cancelEventProp (event) {
  console.debug('Cancelling event prop', { event })
  event.stopPropagation()
}

const NotificationCell = memo(function NotificationsCell ({ cell: { value: row } }) {
  const sourceNotificationValue = parseNotificationFrequencyFromSource(row)
  const [currentValue, setCurrentValue] = useState(sourceNotificationValue)
  const updateFromSourceRef = useRef(false)
  const cycleId = row.id
  const [edit] = useUpdateNotificationFrequencyForCycleMutation()

  console.debug('NotificationsCell updating', { row, currentValue })

  useEffect(() => {
    if (updateFromSourceRef.current) {
      setCurrentValue(sourceNotificationValue)
    } else {
      updateFromSourceRef.current = true
    }
  }, [sourceNotificationValue])

  const onSelect = useCallback((newValue) => {
    const previousValue = currentValue
    console.debug('Called on notification frequency select', { newValue, previousValue, cycleId })
    setCurrentValue(newValue ?? '0')
    edit({ frequency: newValue || '0', cycleId: cycleId })
      .unwrap()
      .then(response => {
        console.info('Got update notification frequency response', { response, newValue, previousValue, cycleId })
        const displayFrequencyName = (cycleNotificationFrequencyIdToName[newValue ?? 0] ?? 'an unexpected value').toLowerCase()
        notifications.show({
          title: 'Notification Frequency Updated',
          message: `Your notification frequency for the selected battery has been successfully updated to ${displayFrequencyName}!`,
          color: 'green'
        })
      })
      .catch(e => {
        console.error('Error updating notification frequency - resetting', { e, newValue, previousValue, cycleId })
        setCurrentValue(previousValue)
        notifications.show({
          title: 'Notification Frequency Error',
          message: 'There was an error updating your notification frequency for a battery! Please try again.',
          color: 'red',
          autoClose: 7000
        })
      })
  }, [currentValue, cycleId, edit])

  return (
    <Flex align='center' justify='center' pos='relative' onClick={cancelEventProp}>
      <Select
        data={cycleNotificationFrequencyOptions}
        pos='absolute'
        value={currentValue}
        onChange={onSelect}
        onClick={cancelEventProp}
        allowDeselect={currentValue !== '0'}
      />
    </Flex>
  )
})

function useBatteryFilters () {
  const organizationsFilter = useOrganizationsFilter({ multiSelect: true })

  return useMemo(() => [
    organizationsFilter,
    {
      id: 'active',
      label: 'Status',
      leftSection: <IconActivity />,
      options: [{ label: 'Open', value: '0' }, { label: 'Closed', value: '1' }]
    }
  ], [organizationsFilter])
}

function useBatteryActions () {
  return useMemo(() => [
    {
      label: 'Edit',
      leftSection: <IconEdit />,
      href: (id) => {
        return `/hire/report-results/${id}/edit`
      }
    }, {
      label: 'Details',
      leftSection: <IconInfoCircle />,
      href: (id) => {
        return `/cycles/${id}/cycleDetails`
      }
    }, {
      label: 'Specs',
      leftSection: <IconAdjustments />,
      href: (id) => {
        return `/hire/report-results/${id}/specs`
      }
    }
  ], [])
}
