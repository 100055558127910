import { ApiMethods, apiSlice, ApiTags } from '../../../react/api'
import { paramsToQuery } from '../../../react/util/queries';

export const emailNotificationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateNotificationFrequencyForCycle: builder.mutation({
      query: ({ cycleId, ...body }) => ({
        url: `email-notifications/cycles/${cycleId}${paramsToQuery(body)}`,
        method: ApiMethods.Post
      }),
      invalidatesTags: (result, error, { cycleId: cycleIdParam }) => [
        { type: ApiTags.Batteries, id: cycleIdParam }
      ]
    })
  })
})

export const { useUpdateNotificationFrequencyForCycleMutation } = emailNotificationsApiSlice
